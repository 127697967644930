import type { IResourceItem } from '@refinedev/core';
import {
  DashboardIcon,
  DoctorIcon,
  NewsletterIcon,
  TransactionListIcon,
} from '../Assets/Svgs';

export const resources: IResourceItem[] = [
  {
    name: 'account-cruds/graph-data-idh',
    list: '/',
    meta: {
      label: 'Dashboard',
      icon: <DashboardIcon />,
    },
  },
  {
    name: 'account-cruds',
    list: '/doctors-list',
    show: '/doctors-list/show/:id',
    meta: {
      label: 'Doctors',
      canCreate: true,
      canEdit: false,
      canDelete: false,
      canShow: true,
      icon: <DoctorIcon />,
    },
  },
  {
    name: 'transaction-list',
    list: '/transaction-list',
    meta: {
      label: 'Transaction List',
      canCreate: true,
      canEdit: false,
      canDelete: false,
      canShow: true,
      icon: <TransactionListIcon />,
    },
  },

  {
    name: 'contact-us',
    list: '/contact-us',
    meta: {
      label: 'Newsletter',
      canCreate: false,
      canEdit: false,
      canDelete: false,
      canShow: false,
      icon: <NewsletterIcon />,
      filters: {
        initial: [
          {
            field: 'account_type',
            operator: 'eq',
            value: 'user',
          },
        ],
      },
    },
  },
];
