import { SVGProps } from 'react';
import { useMenuStore } from '../../Store/IconsStore';

const TransactionListIcon = (props: SVGProps<SVGSVGElement>) => {
  const { isSelected } = useMenuStore();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      {...props}
    >
      <path
        stroke={isSelected === '/transaction-list' ? '#fff' : '#535353'}
        strokeWidth={2}
        d="M1.667 5.833h15m-3.334-4.166L17.5 5.833 13.333 10m5 4.167h-15M6.667 10 2.5 14.167l4.167 4.166"
      />
    </svg>
  );
};

export default TransactionListIcon;
