import React, { useState, useEffect } from 'react';
import { IResourceComponentsProps, useSelect } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import { Button, Form, Input, Select, Table } from 'antd';
import './../styles.css';
import { ResetIcon, SearchIcon } from '../../Assets/Svgs';
import BulkPointsManagement from '@components/BulkImportExport';
import commaNumber from 'comma-number';
import * as XLSX from 'xlsx';

// Define the interfaces for the table data
export interface Root {
  IDHReport: Idhreport[];
  Message: string;
  Result: boolean;
}

export interface Idhreport {
  BusinessLineId: string;
  BusinessLineName: string;
  CustomerName: string;
  MerchantId: string;
  MerchantName: string;
  TransDate: string;
  TransId: string;
  TransTime: string;
  VoucherAmount: string;
}

export const TransactionsList: React.FC<IResourceComponentsProps> = () => {
  const [merchantValue, setMerchantValue] = useState<string | undefined>('all');
  const [categoryValue, setCategoryValue] = useState<string | undefined>('all');
  const [searchQuery, setSearchQuery] = useState<string>(''); // State for search query

  const { tableProps, tableQueryResult, setFilters } = useTable({
    syncWithLocation: true,
    resource: 'rewarding-transactions/epoints/report',
    filters: {
      initial: [],
    },
  });

  interface FiltersData {
    uniqueMerchants: { MerchantId: string; MerchantName: string }[];
    uniqueBusinessLines: { BusinessLineId: string; BusinessLineName: string }[];
  }

  const { queryResult } = useSelect<FiltersData>({
    resource: 'rewarding-transactions/epoints/report/filters',
  });

  const uniqueMerchants = [
    { MerchantId: 'all', MerchantName: 'All' },
    ...((queryResult?.data?.data as unknown as FiltersData)?.uniqueMerchants ||
      []),
  ];
  const uniqueBusinessLines = [
    { BusinessLineId: 'all', BusinessLineName: 'All' },
    ...((queryResult?.data?.data as unknown as FiltersData)
      ?.uniqueBusinessLines || []),
  ];

  // Track filters for merchant and category
  const [filters, setFiltersState] = useState<any[]>([]);

  useEffect(() => {
    // Build the filters
    const newFilters = [];
    if (merchantValue && merchantValue !== 'all') {
      newFilters.push({
        field: 'merchant',
        operator: 'eq' as const,
        value: merchantValue,
      });
    }
    if (categoryValue && categoryValue !== 'all') {
      newFilters.push({
        field: 'category',
        operator: 'eq' as const,
        value: categoryValue,
      });
    }

    if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
      setFiltersState(newFilters);
      setFilters(newFilters, 'replace');
    }
  }, [merchantValue, categoryValue, filters, setFilters]);

  const handleReset = () => {
    setMerchantValue('all'); // Reset state
    setCategoryValue('all'); // Reset state
    setFiltersState([]); // Clear filters state
    setFilters([], 'replace'); // Clear filters
    setSearchQuery(''); // Reset search query
    tableQueryResult.refetch(); // Refetch data
  };

  const handleMerchantChange = (value: string | undefined) => {
    setMerchantValue(value || 'all');
  };

  const handleCategoryChange = (value: string | undefined) => {
    setCategoryValue(value || 'all');
  };

  const filteredData = (
    (tableProps.dataSource as unknown as Root)?.IDHReport || []
  ).filter((item) => {
    return (
      item.CustomerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.MerchantName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.TransId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.TransDate.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const exportToExcel = () => {
    // Create a sheet from filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredData || []);

    // Set column width based on content
    const range = XLSX.utils.decode_range(worksheet['!ref'] || 'A1');
    const colWidths = [];
    for (let col = range.s.c; col <= range.e.c; col++) {
      let maxLength = 0;
      for (let row = range.s.r; row <= range.e.r; row++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell && cell.v) {
          maxLength = Math.max(maxLength, cell.v.toString().length);
        }
      }
      colWidths.push({ wch: maxLength + 2 }); // Add some padding for better readability
    }
    worksheet['!cols'] = colWidths;

    // Create workbook and append sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

    // Write the file
    XLSX.writeFile(workbook, 'Transactions.xlsx');
  };

  return (
    <List
      title={<div className="text-primaryRed lg:mb-4">Transaction List</div>}
      headerButtons={
        <div className="flex gap-4">
          <BulkPointsManagement
            showExtractButton
            showImportButton={false}
            onExtractClick={exportToExcel}
            disabled={filteredData?.length === 0}
          />
        </div>
      }
    >
      <div className="flex flex-col lg:flex-row lg:justify-between items-center mb-8">
        {/* Search and Filters */}
        <Form layout="inline" className="mb-3 w-[100%] lg:w-fit">
          <Form.Item name="search_field">
            <Input
              size="large"
              prefix={<SearchIcon />}
              placeholder="Search Transactions"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} // Update search query on change
              onPressEnter={() => tableQueryResult.refetch()} // Optional if you want to trigger refetch
            />
          </Form.Item>
        </Form>
        <Form layout="inline" className="flex flex-wrap gap-3">
          {/* Merchant Filter */}
          <Form.Item name="merchant">
            <Select
              placeholder="Merchant"
              allowClear
              size="large"
              value={merchantValue === 'all' ? undefined : merchantValue}
              onChange={handleMerchantChange}
            >
              {uniqueMerchants.map(({ MerchantId, MerchantName }) => (
                <Select.Option key={MerchantId} value={MerchantId}>
                  {MerchantName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* Category Filter */}
          <Form.Item name="category">
            <Select
              placeholder="Category"
              allowClear
              size="large"
              value={categoryValue === 'all' ? undefined : categoryValue}
              onChange={handleCategoryChange}
            >
              {uniqueBusinessLines.map(
                ({ BusinessLineId, BusinessLineName }) => (
                  <Select.Option key={BusinessLineId} value={BusinessLineId}>
                    {BusinessLineName}
                  </Select.Option>
                )
              )}
            </Select>
          </Form.Item>
          <Button className="flex items-center gap-1" onClick={handleReset}>
            <ResetIcon />
            Reset
          </Button>
        </Form>
      </div>
      {/* Transactions Table */}
      <Table
        {...tableProps}
        rowKey="id"
        dataSource={filteredData} // Use filtered data
        className="w-full border border-gray-300 rounded-lg"
        onHeaderRow={() => ({ className: 'table-header' })}
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
          pageSize: 9,
        }}
        style={{
          padding: 0,
          overflowX: 'scroll',
          paddingBlockStart: '0 !important',
        }}
      >
        <Table.Column title="Transaction ID" dataIndex="TransId" />
        <Table.Column title="Merchant" dataIndex="MerchantName" />
        <Table.Column title="Category" dataIndex="BusinessLineName" />
        <Table.Column
          title="Date & Time"
          render={(record) => (
            <span>
              {record?.TransDate} - {record?.TransTime}{' '}
            </span>
          )}
        />
        <Table.Column title="Customer Name" dataIndex="CustomerName" />
        <Table.Column
          title="Voucher Amount"
          dataIndex="VoucherAmount"
          render={(value) => (
            <div className="flex flex-row gap-1 items-center justify-center">
              <span className="rounded-full p-2 bg-gray-100 min-w-20 items-center flex justify-center">
                <p className="text-primary font-semibold">
                  EGP {commaNumber(value / 100)}
                </p>
              </span>
              <span className="rounded-full p-2 font-semibold bg-gray-100  min-w-20 items-center flex justify-center">
                {commaNumber(Math.floor(value))} Pts
              </span>
            </div>
          )}
        />
      </Table>
    </List>
  );
};
