import * as React from 'react';
import { SVGProps } from 'react';

const ExtractIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="none"
    className="fill-current text-white transition-colors duration-300"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 14.4A6.4 6.4 0 1 0 8 1.6a6.4 6.4 0 0 0 0 12.8Zm.8-8.8a.8.8 0 0 0-1.6 0v2.869L6.166 7.434a.8.8 0 1 0-1.132 1.132l2.4 2.4a.8.8 0 0 0 1.132 0l2.4-2.4a.8.8 0 0 0-1.132-1.132L8.8 8.47V5.6Z"
      clipRule="evenodd"
    />
  </svg>
);

export default ExtractIcon;
