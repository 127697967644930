import React from 'react';
import { ExtractIcon, ImportIcon } from '../../Assets/Svgs';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface BulkPointsManagementProps {
  title?: string;
  showImportButton?: boolean;
  showExtractButton?: boolean;
  onImportClick?: (value: boolean) => void;
  onExtractClick?: () => void;
  exportLoading?: boolean;
  disabled?: boolean;
}

const BulkPointsManagement: React.FC<BulkPointsManagementProps> = ({
  showImportButton = true,
  showExtractButton = true,
  onExtractClick,
  exportLoading,
  onImportClick,
  disabled,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      {showImportButton && (
        <button
          className="flex items-center gap-x-2 border-primary px-4 py-2 text-primary border rounded-[4px] shadow-sm cursor-pointer hover:bg-primary hover:text-white transition"
          onClick={() => onImportClick?.(true)}
        >
          <div className="flex items-center">
            <div className="font-semibold">Import</div>
            <ImportIcon />
          </div>
        </button>
      )}
      {showExtractButton && (
        <button
          onClick={onExtractClick}
          disabled={disabled}
          className={`flex items-center gap-x-2 px-4 py-2 border rounded-[4px] shadow-sm cursor-pointer transition ${
            disabled
              ? 'bg-gray-400 text-gray-700'
              : 'bg-primary text-white hover:bg-white hover:text-primary group'
          }`}
        >
          {exportLoading ? (
            <div>
              <Loading3QuartersOutlined spin />
            </div>
          ) : (
            <>
              <div className="font-semibold">Extract</div>
              <ExtractIcon className="group-hover:text-primary" />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default BulkPointsManagement;
