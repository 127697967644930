import React, { useState } from 'react';
import { IResourceComponentsProps, useExport } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import { Button, Form, Input, Radio, Select, Table } from 'antd';
import './../styles.css';
import { ColHeaderLeft } from '@components/ColHeaderLeft';
import { FileIcon, ResetIcon, SearchIcon, StarIcon } from '../../Assets/Svgs';
import { activation, speciality } from '../../Constants';
import BulkPointsManagement from '@components/BulkImportExport';
import ImporDoctorstModal from '@components/ImportDoctorModal';
import ActionMenu from '@components/ActionMenu';
import { DoctorRes } from '../../types';
import BulkPointsManagementModal from '@components/BulkPointsManagementModal';
import DoctorImageModal from '@components/DoctorImageModal';
import commaNumber from 'comma-number';

export const DoctorsList: React.FC<IResourceComponentsProps> = () => {
  const [brandValue, setBrandValue] = useState<string>('All');
  const [activationValue, setActivationValue] = useState<string>('All');
  const [minPoints, setMinPoints] = useState('');
  const [maxPoints, setMaxPoints] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [importDoctorModalOpen, setImportDoctorModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [initialImageSrc, setInitialImageSrc] = useState<string>('');
  const [imageId, setImageId] = useState<string>('');
  const [docName, setDocName] = useState<string>('');
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<
    string[]
  >([]);

  const updateImportDoctorModalOpen = (value: boolean) => {
    setImportDoctorModalOpen(value);
  };
  const {
    searchFormProps,
    tableProps,
    tableQueryResult: { refetch },
    filters,
    setFilters,
  } = useTable({
    filters: {
      initial: [{ field: 'account_type', operator: 'eq', value: 'user' }],
    },
    // @ts-ignore
    onSearch: (values: {
      search_field: any;
      brand: string;
      is_deleted: any;
      Speciality_name_en: string[];
      minValue: string;
      maxValue: string;
    }) => {
      let filters = [
        {
          field: 'search_field',
          operator: 'eq',
          value: values.search_field,
        },
        {
          field: 'Specialty_name_en',
          operator: 'eq',
          value: values.Speciality_name_en,
        },
        {
          field: 'fromPoints',
          operator: 'eq',
          value: values.minValue,
        },
        {
          field: 'toPoints',
          operator: 'eq',
          value: values.maxValue,
        },
      ];
      if (values.brand && values.brand !== 'All') {
        filters.push({
          field: 'brand',
          operator: 'eq',
          value: values.brand == 'First Class' ? 'first_class' : 'excellence',
        });
      } else {
        filters = filters.filter((filter) => filter.field !== 'brand');
      }

      if (values.is_deleted && values.is_deleted !== 'All') {
        filters.push({
          field: 'is_deleted',
          operator: 'eq',
          value:
            values.is_deleted == 'Active'
              ? false
              : values.is_deleted == 'Inactive'
                ? true
                : 'all',
        });
      } else {
        filters = filters.filter((filter) => filter.field !== 'is_deleted');
      }

      return filters;
    },

    syncWithLocation: true,
    errorNotification: () => {
      return {
        message: `No results found`,
        type: 'error',
      };
    },
  });

  const handleReset = () => {
    searchFormProps.form?.resetFields();
    setMaxPoints('');
    setMinPoints('');
    //@ts-ignore
    setFilters((currentFilters) => {
      // Preserve the user filter and clear other filters
      return currentFilters.filter(
        (filter: { field: string }) => filter.field === 'account_type'
      );
    }, 'replace');
    refetch();
  };

  const handleSelectChange = (e: string) => {
    setSelectedCheckboxValues((prevValue) => {
      if (prevValue.includes(e)) {
        return prevValue.filter((value) => value !== e);
      } else {
        return [...prevValue, e];
      }
    });
    searchFormProps.form?.setFieldsValue({
      Speciality_name_en: selectedCheckboxValues,
    });
  };
  const updateImageModalOpen = (value: boolean) => {
    setImageModalOpen(value);
  };
  const { triggerExport, isLoading: exportLoading } = useExport({
    resource: 'account-cruds',
    filters: filters,
    mapData: (item: DoctorRes) => {
      return {
        ID: item.id,
        Name: item.name,
        'Mobile number': item.phone_number,
        Speciality: item?.user_profile?.specialty?.Specialty_name_en || '-',
        Email: item.email || '-',
        'First Class':
          item?.SystemConfigID === 288 ? item.card_details?.AllPoints : '-',
        Excellence:
          item?.SystemConfigID != 288 ? item.card_details?.AllPoints : '-',
        UniqueID: item?.account_unique_custom_field[0]?.value || '-',
      };
    },
  });
  return (
    <List
      title={<div className="text-primaryRed lg:mb-4">Doctor List</div>}
      headerButtons={
        <div className="flex gap-4">
          <Button
            className="flex items-center text-elborgPrimary text-base font-semibold border-0 bg-none shadow-none"
            onClick={() => setModalOpen(true)}
          >
            <div>Bulk Points Management</div>
            <StarIcon />
          </Button>
          <BulkPointsManagement
            title="Bulk Points Management"
            showImportButton
            showExtractButton
            onImportClick={updateImportDoctorModalOpen}
            onExtractClick={triggerExport}
            exportLoading={exportLoading}
          />
        </div>
      }
    >
      <div className="flex flex-col lg:flex-row lg:justify-between self-stretch align-items-center mb-8">
        <Form
          {...searchFormProps}
          layout="inline"
          className="mb-3 w-[100%] lg:w-fit"
        >
          <Form.Item
            name="search_field"
            className=" lg:w-[270px] lg:overflow-hidden"
          >
            <Input
              size="large"
              // styles={{ input: { width: '29.136690647482016%' } }}
              prefix={
                <SearchIcon
                  className="cursor-pointer"
                  onClick={() => {
                    const idValue =
                      searchFormProps?.form?.getFieldValue('search_field');
                    searchFormProps.form?.setFieldsValue({
                      search_field: idValue,
                    });
                    searchFormProps.form?.submit();
                  }}
                />
              }
              placeholder="Search & Filter Doctors"
              styles={{ affixWrapper: { padding: '12px' } }}
            />
          </Form.Item>
        </Form>
        <div className="flex items-center">
          <Form
            {...searchFormProps}
            layout="inline"
            className="flex w-full lg:items-center flex-col lg:flex-row gap-3 lg:gap-0  "
            onValuesChange={() => {
              searchFormProps.form?.submit();
            }}
          >
            <Form.Item>
              <Select
                dropdownStyle={{ width: 'fit-content' }}
                placeholder="Select Points Range"
                allowClear
                size="large"
                dropdownRender={() => (
                  <div style={{ padding: '8px' }}>
                    <Form.Item
                      name="minValue"
                      rules={[
                        {
                          validator: (_, value) => {
                            const maxValue =
                              searchFormProps.form?.getFieldValue('maxValue');
                            if (
                              value &&
                              maxValue &&
                              Number(value) > Number(maxValue)
                            ) {
                              return Promise.reject(
                                new Error(
                                  'Min points cannot be greater than Max points'
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <div className="flex flex-col gap-y-1">
                        <span className="text-sm text-gray400">From</span>
                        <Input
                          placeholder="Min points"
                          value={minPoints}
                          type="number"
                          onChange={(e) => setMinPoints(e.target.value)}
                          style={{
                            borderRadius: '8px',
                            backgroundColor: '#f5f5f5',
                            border: 'none',
                            padding: '12px',
                          }}
                        />
                      </div>
                    </Form.Item>
                    <Form.Item name="maxValue">
                      <div className="flex flex-col gap-y-1">
                        <span className="text-sm text-gray400">To</span>
                        <Input
                          placeholder="Max points"
                          value={maxPoints}
                          type="number"
                          onChange={(e) => {
                            setMaxPoints(e.target.value);
                            searchFormProps.form?.setFieldsValue({
                              maxValue: e.target.value,
                            });
                          }}
                          style={{
                            borderRadius: '8px',
                            backgroundColor: '#f5f5f5',
                            border: 'none',
                            padding: '12px',
                          }}
                        />
                      </div>
                    </Form.Item>
                    <div className="flex justify-end">
                      <Button
                        onClick={() => {
                          handleReset();
                          searchFormProps.form?.resetFields();
                        }}
                        style={{
                          padding: '0',
                          boxShadow: 'none',
                          border: 'none',
                          backgroundColor: 'transparent',
                        }}
                        disabled={!minPoints && !maxPoints}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                )}
                style={{ padding: '-12px' }}
              />
            </Form.Item>
            <Form.Item name="brand">
              <Select
                onChange={(value) => {
                  setBrandValue(value as string);
                  searchFormProps.form?.setFieldsValue({ brand: value });
                }}
                dropdownStyle={{ width: 'fit-content' }}
                placeholder={`Brand: ${brandValue}`}
                allowClear
                size="large"
                dropdownRender={() => (
                  <div>
                    <Radio.Group
                      onChange={(e) => {
                        setBrandValue(e.target.value as string);
                        searchFormProps.form?.setFieldsValue({
                          brand: e.target.value,
                        });
                        searchFormProps.form?.submit();
                      }}
                      value={brandValue}
                      style={{ padding: '8px' }}
                    >
                      <Radio
                        className="w-full p-2 cursor-pointer"
                        style={{ display: 'block' }}
                        value="All"
                        onClick={() => {
                          //@ts-ignore
                          setFilters((currentFilters) => {
                            // Preserve the user filter and clear other filters
                            return currentFilters.filter(
                              (filter: { field: string }) =>
                                filter.field === 'brand'
                            );
                          }, 'replace');
                          refetch();
                        }}
                      >
                        All
                      </Radio>
                      <Radio
                        className="w-full p-2 cursor-pointer"
                        style={{ display: 'block' }}
                        value="First Class"
                      >
                        First Class
                      </Radio>
                      <Radio
                        className="w-full p-2 cursor-pointer"
                        value="Excellence"
                      >
                        Excellence
                      </Radio>
                    </Radio.Group>
                  </div>
                )}
              />
            </Form.Item>
            <Form.Item name="is_deleted">
              <Select
                onChange={(value) => {
                  setActivationValue(value as string);
                  searchFormProps.form?.setFieldsValue({ is_deleted: value });
                }}
                dropdownStyle={{ width: 'fit-content' }}
                placeholder={`Activation: ${activationValue}`}
                allowClear
                size="large"
                dropdownRender={() => (
                  <div>
                    <Radio.Group
                      onChange={(e) => {
                        setActivationValue(e.target.value as string);
                        searchFormProps.form?.setFieldsValue({
                          is_deleted: e.target.value,
                        });
                        searchFormProps.form?.submit();
                      }}
                      value={activationValue}
                      style={{ padding: '8px' }}
                    >
                      <Radio
                        className="w-full p-2 cursor-pointer"
                        key={'All'}
                        value={'All'}
                        onClick={() => {
                          //@ts-ignore
                          setFilters((currentFilters) => {
                            // Preserve the user filter and clear other filters
                            return currentFilters.filter(
                              (filter: { field: string }) =>
                                filter.field === 'account_type'
                            );
                          }, 'replace');
                          refetch();
                        }}
                      >
                        All
                      </Radio>
                      {activation.map(({ value, label }) => (
                        <Radio
                          key={value}
                          className="w-full p-2 cursor-pointer"
                          style={{ display: 'block' }}
                          value={label}
                        >
                          {label}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </div>
                )}
              />
            </Form.Item>
            <Form.Item name="Speciality_name_en">
              <Select
                value={selectedCheckboxValues}
                onSelect={handleSelectChange}
                dropdownStyle={{ width: 'fit-content', minWidth: '200px' }}
                placeholder={'Speciality: All'}
                allowClear
                size="large"
                options={speciality}
                mode="multiple"
                className="min-w-[131px]"
                maxTagTextLength={0}
              />
            </Form.Item>
            <Button
              onClick={() => handleReset()}
              className="flex items-center  gap-1  rounded-md border border-gray-300 bg-gray100 p-1 max-w-20 mb-1 lg:mb-0"
            >
              <ResetIcon />
              <div className="text-gray400">Reset</div>
            </Button>
          </Form>
        </div>
      </div>
      <Table
        {...tableProps}
        className="w-full border border-gray-300 rounded-lg"
        onHeaderRow={() => ({ className: 'table-header' })}
        rowKey="id"
        bordered={false}
        pagination={{
          position: ['bottomCenter'],
          nextIcon: false,
          prevIcon: false,
          pageSize: 9,
        }}
        style={{
          padding: 0,
          overflowX: 'scroll',
          paddingBlockStart: '0 !important',
        }}
      >
        <Table.Column
          className="table-header"
          dataIndex="id"
          title={<ColHeaderLeft title="ID" />}
          render={(value) => (
            <span className="text-gray500 font-semibold">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="name"
          title={<ColHeaderLeft title="Name" />}
          render={(value) => (
            <span className="text-gray500 font-semibold">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="phone_number"
          title={<ColHeaderLeft title="Mobile number" />}
          render={(value) => (
            <span className="w-[100%] font-normal text-gray500">{value}</span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex={['user_profile', 'specialty', 'Specialty_name_en']}
          title={'Speciality'}
          render={(value) => (
            <span className="flex justify-center w-[100%] font-normal text-gray500">
              {value}
            </span>
          )}
        />
        <Table.Column
          className="table-header"
          dataIndex="email"
          title={'Email'}
          render={(value) => (
            <span className="flex justify-center w-[100%] font-normal text-gray500">
              {value}
            </span>
          )}
        />
        <Table.Column<DoctorRes>
          className="table-header"
          dataIndex={['card_details', 'AllPoints']}
          title={'First class'}
          render={(value, record) => {
            return (
              <span className="flex justify-center items-center w-[100%] font-semibold text-elmokhtabarPrimary gap-1">
                {record.systemConfigs.BundleID ==
                'idh.firstclass.loynova.loyalty' ? (
                  <>
                    <span> {commaNumber(+`${value / 10}`.split('.')[0])}</span>
                    <span className="font-semibold" style={{ fontSize: '8px' }}>
                      {value && 'PTS'}
                    </span>
                  </>
                ) : (
                  <span className="text-gray500">-</span>
                )}
              </span>
            );
          }}
        />
        <Table.Column<DoctorRes>
          className="table-header"
          dataIndex={['card_details', 'AllPoints']}
          title={'Excellence'}
          render={(value, record) => {
            return (
              <span className="flex justify-center items-center w-[100%] font-semibold text-elborgPrimary gap-1">
                {record.systemConfigs.BundleID ==
                'idh.excellence.loynova.loyalty' ? (
                  <>
                    <span> {commaNumber(+`${value / 10}`.split('.')[0])}</span>
                    <span className="font-semibold" style={{ fontSize: '8px' }}>
                      {value && 'PTS'}
                    </span>
                  </>
                ) : (
                  <span className="text-gray500">-</span>
                )}
              </span>
            );
          }}
        />
        <Table.Column<DoctorRes>
          className="table-header"
          title={<ColHeaderLeft title="Files" />}
          render={(record) => (
            <FileIcon
              className="cursor-pointer"
              onClick={() => {
                setImageId(
                  record?.user_profile.upload_id
                    ? record.user_profile.upload_id
                    : null
                );
                setDocName(record?.name);
                updateImageModalOpen(true);
              }}
            />
          )}
        />
        <Table.Column<DoctorRes>
          className="table-header"
          title="Actions"
          render={(record) => (
            <span className="flex justify-center text-neutral90">
              <ActionMenu record={record} refetch={refetch} />
            </span>
          )}
        />
      </Table>
      <ImporDoctorstModal
        open={importDoctorModalOpen}
        updateModalOpen={updateImportDoctorModalOpen}
      />
      <BulkPointsManagementModal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
        doctors={(tableProps.dataSource as DoctorRes[]) || []}
      />
      <DoctorImageModal
        updateImageModalOpen={updateImageModalOpen}
        open={imageModalOpen}
        imageId={imageId}
        initialImageSrc={initialImageSrc}
        setInitialImageSrcList={setInitialImageSrc}
        name={docName}
        ImageModalOpen={imageModalOpen}
      />
    </List>
  );
};
