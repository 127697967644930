import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Authenticated, ErrorComponent } from '@refinedev/core';
import { CatchAllNavigate } from '@refinedev/react-router-v6';
import Login from '../pages/login';
import { ThemedLayoutV2 } from '../components/layout';
import { ContactUsList } from '../pages/Newsletter';
import { DashboardPage } from '@pages/Dashboard';
import { DoctorsList } from '@pages/Doctors';
import { DoctorShow } from '@pages/Doctors/show';
import { TransactionsList } from '@pages/Transactions';
import SharedWallet from '@pages/SharedWallet';

const Stack = () => {
  // Check if resetPasswordEmail exists in local storage
  return (
    <Routes>
      <Route path="/error404" element={<ErrorComponent />} />

      <Route
        element={
          <Authenticated
            key="authenticated-inner"
            fallback={<CatchAllNavigate to="/login" />}
          >
            {/* <NavigateToResource resource="transaction/find-all/admin" /> */}
            <ThemedLayoutV2>
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route path="*" element={<ErrorComponent />} />
        <Route index element={<DashboardPage />} />
        <Route path="/doctors-list" element={<DoctorsList />} />
        <Route path="/transaction-list" element={<TransactionsList />} />
        <Route path="/doctors-list/show/:id" element={<DoctorShow />} />
        <Route path="/doctors-list/shared-wallet" element={<SharedWallet />} />
        <Route path="/contact-us">
          <Route index element={<ContactUsList />} />
        </Route>
      </Route>
      <Route
        element={
          <Authenticated
            key="authenticated-outer"
            fallback={<Outlet />}
          ></Authenticated>
        }
      >
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
};

export default Stack;
